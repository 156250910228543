import { onMounted,onUnmounted, ref } from 'vue'
import DataUtil from '../utils/utils'
export default function useScroll(elRef){
  let el = window
  let isReachBottom = ref(false)
  let clientHeight = ref(0)
  let scrollTop = ref(0)
  let scrollHeight = ref(0)
 
  const scrollListenerHandler = DataUtil.throttle(() => {
    if(el === window){
      clientHeight.value =  document.documentElement.clientHeight
      scrollHeight.value = document.documentElement.scrollHeight
      scrollTop.value = document.documentElement.scrollTop
    }else {
      clientHeight.value = el.clientHeight
      scrollTop.value = el.scrollTop
      scrollHeight.value = el.scrollHeight
    }
    if(clientHeight.value + scrollTop.value >= scrollHeight.value){
      isReachBottom.value = true
    }
  },0)
  onMounted(()=> {
    if(elRef) {
      el = elRef
    }
    setTimeout(function(){
      el.addEventListener("scroll", scrollListenerHandler)
    },300)
    
    
  })
  onUnmounted(()=>{
    el.removeEventListener("scroll", scrollListenerHandler)
  })
 
  return { isReachBottom, clientHeight, scrollTop, scrollHeight }
}