import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import lazyPlugin from 'vue3-lazy';
import  './style/comme.css'
import  './style/custom.css'
import  './style/iconfont/iconfont.css'
import AntDesignVue from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

require('./mock');


createApp(App).use(router).use(store).use(AntDesignVue).use(lazyPlugin, {
    // error: require('./assets/master-tonic-recipe-1_mini.jpg'),// 加载错误的图片
    // loading: require('./assets/loadingimg.png'), // 加载时的图片
    }).mount('#app')
