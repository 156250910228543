import { createStore } from 'vuex'



const store = createStore({
        state : {
            activeidx : 0
        },
        getters : {
            getactiveidx : state => state.activeidx,
        },
        mutations : {
            SETACTIVEIDX : (state,i) => 
            state.activeidx = i.value
        },
        actions : {
            
        }
})


export default store