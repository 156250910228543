const DataUtil = {
    getLocalTime() {     
        var now = new Date();
        var yy = now.getFullYear(); //年
        var mm = now.getMonth() + 1; //月
        var dd = now.getDate(); //日
        var hh = now.getHours(); //时
        var ii = now.getMinutes(); //分
        var ss = now.getSeconds(); //秒
        var time = yy + "-";
        if (mm < 10) time += "0";
        time += mm + "-";
        if (dd < 10) time += "0";
        time += dd + " ";
        if (hh < 10) time += "0";
        time += hh + ":";
        if (ii < 10) time += '0';
        time += ii + ":";
        if (ss < 10) time += '0';
        time += ss;
        return time
    },  
    throttle(fn, interval) {
        var enterTime = 0; //触发的时间
        var gapTime = interval; //间隔时间，如果interval不传，则默认2000ms
        return function (e) {
          var that = this;
          var backTime = new Date(); //第一次函数return即触发的时间
          if (backTime - enterTime > gapTime) {
            fn.call(that, e, arguments);
            enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
          }
        };
    },
    ////归并排序
    mergeSort(arr) {
      if (arr.length <= 1) {
        return arr;
      }
      const mid = Math.floor(arr.length / 2);
      const leftArr = arr.slice(0, mid);
      const rightArr = arr.slice(mid);
      return this.merge(this.mergeSort(leftArr), this.mergeSort(rightArr));
    },
    
    merge(leftArr, rightArr) {
      const result = [];
      let leftIndex = 0;
      let rightIndex = 0;
      while (leftIndex < leftArr.length && rightIndex < rightArr.length) {
        if (leftArr[leftIndex] < rightArr[rightIndex]) {
          result.push(leftArr[leftIndex]);
          leftIndex++;
        } else {
          result.push(rightArr[rightIndex]);
          rightIndex++;
        }
      }
      return result.concat(leftArr.slice(leftIndex)).concat(rightArr.slice(rightIndex));
    },
};

export default DataUtil;