<template>
    <div>
        <div id="wrap" ref="scrollContainer" >
          <top-nav id="header" :isFixed="isFixed"></top-nav>
          <div class="wrap" >
            <router-view />
          </div>
        </div>
    </div>
</template>
<script>
import topNav from '../components/topnav.vue';
import useScroll from '../hooks/pagescroll';
import {computed, ref,onMounted,watch} from 'vue';
import { useRoute } from 'vue-router';
export default {
  components: {
    topNav,
  },
  data(){
    return{

    }
  }, 
  setup(){
    let isHomeroute = ref(false);
    const route = useRoute();
    let { scrollTop ,scrollHeight}= useScroll(); 
    let isFixed = computed(()=>{
      // console.log(scrollTop.value)
        return scrollTop.value > 10
    });
    watch(()=>route.path,(newVal,oldVal)=>{
        if(newVal == '/home'){
          isHomeroute.value = true
        }else{
          isHomeroute.value = false
        }
    })
    onMounted(()=>{

    })
    
    function Collapseop(d){
      
    };
    return{
      Collapseop,
      isHomeroute,
      isFixed,
    }
  },
  methods:{

  },
  mounted(){
      // console.log(document.getElementById('wrap').clientWidth)
  },
  destroyed () {

  }
};
</script>
<style  scoped>
.wrap{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
}

</style>