import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: ''
    },
    redirect: {
			path: '/home'
		},
    children:[
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "" */ '../views/main/main.vue'),
            meta: {
              title: 'home'
            },
        },
    ]
  },
  {
		path:  "/:pathMatch(.*)*",
    name: '404',
    component: () => import(/* webpackChunkName: "" */ '../views/notfound.vue')
		
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router